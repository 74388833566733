import React, { useState } from 'react';
import { Form, Formik, useField } from 'formik';
import { Link, navigate } from 'gatsby';
import Button from '../common/Button';
import Card from '../common/Card';
import { FormError, FormInput, Label } from '../common/FormInput';
import SEO from '../Seo';
import * as Yup from 'yup';
import { getUrl } from '../../util';

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required')
});

interface ResetPasswordProps {
  path: string;
}

enum ResetPasswordState {
  IDLE,
  LOADING,
  SUCCEEDED,
  FAILED
}

const ResetPassword = (props: ResetPasswordProps) => {
  const [resetPasswordState, setResetPasswordState] = useState(
    ResetPasswordState.IDLE
  );
  const [
    resetPasswordFailureMessage,
    setResetPasswordFailureMessage
  ] = useState(null);
  const submitResetPassword = async (values: { email: string }) => {
    try {
      const data = {
        email: values.email
      };

      setResetPasswordState(ResetPasswordState.LOADING);

      const response = await fetch(
        `${process.env.GATSBY_API_URL}/resetPassword`,
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (!response.ok) {
        const body = await response.json();
        const errorMessage = body.message ?? 'Something went wrong';
        setResetPasswordFailureMessage(errorMessage);
        setResetPasswordState(ResetPasswordState.FAILED);
        throw new Error();
      }
      setResetPasswordState(ResetPasswordState.SUCCEEDED);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="h-screen bg-gray-100">
      <SEO title="Reset Password" />
      <section className="w-full h-full p-8 m-auto sm:w-1/2 sm:pt-10">
        <Card>
          <div className="mb-10 -mx-8 -my-6">
            <div className="mt-12">
              <Link to={getUrl('')}>
                <img
                  src={require(`./../../images/logo_sprout.png`)}
                  alt="Logo"
                  className="w-full sm:w-1/2 m-auto"
                />
              </Link>
            </div>
          </div>

          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            onSubmit={values => submitResetPassword(values)}
            validationSchema={ResetPasswordSchema}
          >
            {({ errors, touched }) => (
              <Form className="mt-8">
                <>
                  {resetPasswordState === ResetPasswordState.FAILED && (
                    <div className="p-4 mb-4 bg-red-100 border border-red-600 rounded-lg">
                      <p className="text-red-600 sans-serif">
                        {resetPasswordFailureMessage}
                      </p>
                    </div>
                  )}
                </>
                <div>
                  <h2 className="mb-6 text-3xl font-bold text-primary sans-serif">
                    Reset Password
                  </h2>
                  <div className="mb-4">
                    <div className="mb-2">
                      <Label text="Email" />
                    </div>
                    <FormInput
                      ariaLabel="Email"
                      name="email"
                      type="text"
                      placeholder="Email"
                    />
                  </div>
                </div>

                {resetPasswordState === ResetPasswordState.SUCCEEDED && (
                  <div className="p-4 mt-4 bg-green-100 border border-green-700 rounded-lg">
                    <p className="text-lg text-green-700 sans-serif">
                      A message has been sent to the supplied email address.
                      Please follow the instructions in that message to login.
                    </p>
                  </div>
                )}

                <div className="mt-6">
                  <Button
                    type="submit"
                    text="Reset Password"
                    isLoading={
                      resetPasswordState === ResetPasswordState.LOADING
                    }
                    disabled={!!errors.email}
                  />
                  <Link
                    to="/auth/login"
                    className="ml-4 text-primary sans-serif hover:underline hover:text-primary-dark"
                  >
                    &larr; Back to Login
                  </Link>
                </div>
                <span className="flex flex-wrap items-center justify-center w-full py-6 text-sm font-semibold text-center text-gray-600 sans-serif">
                  If you are experiencing trouble signing in, please contact us
                  at &nbsp;
                  <a
                    href="mailto:support@readingwriting.net"
                    className="text-blue-500 underline sans-serif"
                  >
                    support@readingwriting.net
                  </a>
                </span>
              </Form>
            )}
          </Formik>
        </Card>
      </section>
    </div>
  );
};

export default ResetPassword;
