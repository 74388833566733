import { useLocation } from '@reach/router';
import { Form, Formik } from 'formik';
import { Link, graphql, navigate, useStaticQuery } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { AuthContext } from '../../context/AuthContext';
import { getUserInfo } from '../../util/auth';
import Button from '../common/Button';
import { FormInput, Label } from './../common/FormInput';
import { setCookie } from '../../util/cookie';
import { applyToEvent, getEvent } from './api';
import ApplyToEventBlock from './ApplyToEventBlock';
import { checkEventApplicationConfigsForRegister } from '../../helpers/eventApplication.helper';

function getIntended(queryParams: string) {
  const querySplit = location.search.split('?');
  if (querySplit && querySplit[1]) {
    const intended = querySplit[1].split('=');
    if (intended && intended[1]) {
      return querySplit[2] ? intended[1] + `?${querySplit[2]}` : intended[1];
    }
    return '';
  }
  return '';
}

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string().required('Required')
});

enum LoginState {
  IDLE,
  LOADING,
  PENDING,
  SUCCEEDED,
  FAILED
}

function removeIssQueryParam(url: string): string {
  // Create a URL object from the input string
  const urlObj = new URL(url);

  // Remove 'iss' query parameter if it exists
  urlObj.searchParams.delete('iss');

  // Return the modified URL string
  return urlObj.toString();
}

interface LoginProps {
  path: string;
}

const Login = (props: LoginProps) => {
  const location = useLocation();
  const intended = getIntended(location.search);
  const eventId = new URLSearchParams(location.search).get('applyToEvent');
  const auth = useContext(AuthContext);
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "images/logo_sprout.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const [eventToApplyTo, setEventToApplyTo] = useState<any>(null);

  useEffect(() => {
    const checkForUser = async () => {
      const user = await getUserInfo();
    };
    checkForUser();

    if (location.search.includes('?iss=')) {
      navigate(removeIssQueryParam(location.href));
    }
  }, []);

  useEffect(() => {
    if (eventId) {
      const loadEvent = async () => {
        const result = await getEvent(eventId);
        setEventToApplyTo(result.event);
      };
      loadEvent();
    }
  }, [eventId]);

  const [loginState, setLoginState] = useState(LoginState.IDLE);
  const [loginPendingMessage, setLoginPendingMessage] = useState<string | null>(
    null
  );
  const [loginFailureMessage, setLoginFailureMessage] = useState<string | null>(
    null
  );
  const submitLogin = async (values: { email: string; password: string }) => {
    try {
      setLoginState(LoginState.LOADING);
      const req = await fetch(`${process.env.GATSBY_API_URL}/login`, {
        method: 'POST',
        body: JSON.stringify({
          email: values.email,
          password: values.password
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const res = await req.json();

      if (res.state === 'PENDING') {
        setLoginState(LoginState.PENDING);
        setLoginPendingMessage(res.message);
        return;
      }
      if (res.state === 'ERROR') {
        setLoginState(LoginState.FAILED);
        setLoginFailureMessage(res.message);
        return;
      }
      if (res.state === 'SUCCESS') {
        setCookie('access_token', res.data.access_token);
        auth.setAuthState(res.data);
        if (eventToApplyTo) {
          const {
            eventApplicationId,
            isApplyNowAvailable,
            isContinueAvailable
          } = await checkEventApplicationConfigsForRegister(eventToApplyTo.id);

          if (isApplyNowAvailable) {
            const result = await applyToEvent(eventToApplyTo.id);
            navigate(
              `/members/events/applications/${result.data.id}/manage/general-information`
            );
          } else if (isContinueAvailable) {
            navigate(
              `/members/events/applications/${eventApplicationId}/manage/general-information`
            );
          } else {
            navigate(`/members/events/applications`);
          }

          return;
        }
        navigate(intended || '/members/profile');
        return;
      }
    } catch (err) {
      setLoginState(LoginState.FAILED);
      setLoginFailureMessage('Something went wrong');
      return;
    }
  };
  return (
    <Formik
      initialValues={{
        email: '',
        password: ''
      }}
      onSubmit={values => submitLogin(values)}
      validationSchema={LoginSchema}
    >
      {({ errors }) => (
        <Form className="mt-8">
          <section>
            {loginState === LoginState.FAILED ? (
              <div className="p-4 mb-4 bg-red-100 border border-red-600 rounded-lg">
                <p className="text-red-600 sans-serif">{loginFailureMessage}</p>
              </div>
            ) : null}
            {loginState === LoginState.PENDING ? (
              <div className="p-4 mb-4 bg-yellow-100 border border-yellow-600 rounded-lg">
                <p className="text-yellow-600 sans-serif">
                  {loginPendingMessage}
                </p>
              </div>
            ) : null}
          </section>
          <div>
            {eventId && (
              <ApplyToEventBlock eventToApplyTo={eventToApplyTo} type="login" />
            )}
            <h2 className="mb-4 text-3xl font-bold text-primary sans-serif">
              Log In
            </h2>
            <div className="mb-4">
              <div className="mb-2">
                <Label text="Email" />
              </div>
              <FormInput
                ariaLabel="Email"
                name="email"
                type="text"
                placeholder="Email"
              />
            </div>
            <div>
              <div className="mb-2">
                <Label text="Password" />
              </div>
              <FormInput
                ariaLabel="Password"
                name="password"
                type="password"
                placeholder="Password"
              />
            </div>
          </div>

          <div className="flex flex-col mt-6 md:flex-row">
            <Button
              type="submit"
              text="Log In"
              isLoading={loginState === LoginState.LOADING}
              disabled={!!errors.email || !!errors.password}
            />
            <Link
              to="/auth/reset-password"
              className="my-auto mt-2 ml-6 text-primary sans-serif hover:underline hover:text-primary-dark"
            >
              Forgot Password
            </Link>
            <Link
              to={`/auth/signup${
                eventToApplyTo ? `?applyToEvent=${eventToApplyTo.id}` : ''
              }`}
              className="my-auto ml-6 text-primary sans-serif hover:underline hover:text-primary-dark"
            >
              Sign Up for an Account
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Login;
