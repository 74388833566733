import { Link } from 'gatsby';
import React from 'react';
import Card from '../common/Card';
import SEO from '../Seo';
import { getUrl } from '../../util';
import Signup from './Signup';
import Login from './Login';

interface AuthWrapperProps {
  path: string;
}

const AuthWrapper = (props: AuthWrapperProps) => {
  return (
    <div className="h-full bg-gray-100">
      <SEO title={props.path === 'signup' ? 'Signup' : 'Log In'} />
      <section className="w-full h-full p-8 m-auto xl:w-1/2 lg:w-1/2 sm:pt-10">
        <Card>
          <div className="mb-8 -mx-8 -my-6">
            <div className="mt-8">
              <Link to={getUrl('')}>
                <img
                  src={require(`./../../images/logo_sprout.png`)}
                  alt="Logo"
                  className="w-full sm:w-1/2 m-auto"
                />
              </Link>
            </div>
          </div>
          {props.path === 'signup' ? (
            <Signup path={props.path} />
          ) : (
            <Login path={props.path} />
          )}
          <span className="flex flex-wrap items-center justify-center w-full py-4 text-sm font-semibold text-center text-gray-600 sans-serif">
            If you are experiencing trouble signing in, please contact us at
            &nbsp;
            <a
              href="mailto:support@readingwriting.net"
              className="text-blue-500 underline sans-serif"
            >
              support@readingwriting.net
            </a>
          </span>
        </Card>
      </section>
    </div>
  );
};

export default AuthWrapper;
