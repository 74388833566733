import {
  getEventApplication,
  getEventApplicationByEventId
} from '../components/members/api';

export const checkEventApplicationConfigsForRegister = async (
  eventId: string
) => {
  let isContinueAvailable = false;
  let isViewAvailable = false;
  let isApplyNowAvailable = false;
  let eventApplicationId = null;

  eventApplicationId = (await getEventApplicationByEventId(eventId))
    ?.eventApplicationId;

  if (eventApplicationId) {
    const eventApplication = (await getEventApplication(eventApplicationId))
      ?.application;
    switch (eventApplication?.status) {
      case 'STARTED':
        isContinueAvailable = true;
        break;
      case 'REJECTED':
        isApplyNowAvailable = true;
        break;
      default: {
        isViewAvailable = true;
        break;
      }
    }
  } else {
    isApplyNowAvailable = true;
  }

  return {
    isContinueAvailable,
    isViewAvailable,
    isApplyNowAvailable,
    eventApplicationId
  };
};
