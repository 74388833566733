import React from 'react';

import { Link } from 'gatsby';
import { Event } from './NewApiTypes.generated';

const ApplyToEventBlock = (props: {
  eventToApplyTo: Event;
  type: 'signup' | 'login';
}) => {
  const { eventToApplyTo, type } = props;
  return (
    <>
      <div className="min-h-24 w-56 mx-auto flex justify-center right-0 bg-gray-300 bg-opacity-50 p-2 rounded-lg mb-4">
        <Link
          to={`/auth/login${
            eventToApplyTo ? `?applyToEvent=${eventToApplyTo.id}` : ''
          }`}
          className={`flex-1 text-center my-auto mr-1 text-primary sans-serif hover:underline hover:text-primary-dark p-1 rounded-lg ${
            type === 'login' ? 'bg-white' : ''
          }`}
        >
          Log in
        </Link>
        <Link
          to={`/auth/signup${
            eventToApplyTo ? `?applyToEvent=${eventToApplyTo.id}` : ''
          }`}
          className={`flex-1 text-center my-auto ml-1 text-primary sans-serif hover:underline hover:text-primary-dark p-1 rounded-lg  ${
            type === 'signup' ? 'bg-white' : ''
          }`}
        >
          Sign Up
        </Link>
      </div>
      <h4 className="mb-4 text-primary">
        To apply to <b>{eventToApplyTo?.name}</b> event, you first need to
        register for a RWP-M account. If you already have one, please sign in to
        continue
      </h4>
    </>
  );
};

export default ApplyToEventBlock;
